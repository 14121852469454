.wrap {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.inner {
  max-width: 1000px;
  height: 100%;
  width: 100%;
  align-items: center;
  position: relative;
}

.text {
  margin-bottom: 4px;
}

.textStatus {
  font-size: 108px;
}

.stars {
  position: absolute;
  top: 0;
  right: 0;
}

.dark {
  .textStatus {
    color: var(--white);
  }
  .text {
    color: var(--grey18);
  }
  .stars {
    width: 1220px;
    height: 940px;
  }
}

.light {
  .textStatus {
    color: var(--black);
  }
  .text {
    color: var(--grey18);
  }
  .stars {
    width: 730px;
    height: 900px;
  }
}

.iconWrap {
  position: absolute;
  bottom: 15%;
  right: 5%;
}

.astronaut {
  width: 352px;
  height: 352px;
  position: relative;
}

.circle {
  position: absolute;
  width: 370px;
  height: 130px;
  bottom: -70px;
}

@media screen and (max-width: 1440px) {
  .inner {
    max-width: 900px;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    max-width: 700px;
  }
  .astronaut {
    width: 208px;
    height: 208px;
  }
  .circle {
    width: 266px;
    height: 94px;
    bottom: -70px;
  }
}

@media screen and (max-width: 768px) {
  .inner {
    max-width: 500px;
  }
}

@media screen and (max-width: 568px) {
  .inner {
    max-width: 380px;
  }
  .textStatus {
    font-size: 103px;
  }
  .astronaut {
    width: 160px;
    height: 160px;
  }
  .circle {
    width: 175px;
    height: 60px;
    bottom: -45px;
  }
}

@media screen and (max-width: 468px) {
  .inner {
    max-width: 280px;
  }
}